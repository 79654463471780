import appSettings from '../../../../config/settings';
import { createInstance } from '../../../../util/sdkLoader';
import * as apiUtils from '../../../../util/api';

const getSDK = () => {
  if (typeof window !== 'undefined') {
    const baseUrl = appSettings.sdk.baseUrl ? { baseUrl: appSettings.sdk.baseUrl } : {};
    const assetCdnBaseUrl = appSettings.sdk.assetCdnBaseUrl
      ? { assetCdnBaseUrl: appSettings.sdk.assetCdnBaseUrl }
      : {};

    return createInstance({
      transitVerbose: appSettings.sdk.transitVerbose,
      clientId: appSettings.sdk.clientId,
      secure: appSettings.usingSSL,
      typeHandlers: apiUtils.typeHandlers,
      ...baseUrl,
      ...assetCdnBaseUrl,
    });
  }
  return null;
};

const sdk = getSDK();

export default sdk;
