import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import React from 'react';

import SectionListingsCarousel from './SectionListingsCarousel';
import SectionCarouselDefault from './SectionCarouselDefault';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionCarouselCustom = props => {
  const { sectionName } = props;

  if (/^dynamic-listings-carousel/.test(sectionName)) {
    return <SectionListingsCarousel {...props} />;
  }

  return <SectionCarouselDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCarouselCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  options: null,
};

SectionCarouselCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionCarouselCustom;
