import React from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';

import BlockContainer from '../BlockContainer';

import css from './BlockListing.module.css';
import { ListingCard } from '../../../../components';
import { propTypes } from '../../../../util/types';
import { useIntl } from 'react-intl';

const BlockListing = props => {
  const { blockId, className, rootClassName, listing, callToAction } = props;
  const classes = classNames(rootClassName || css.root, className);
  const intl = useIntl();

  return (
    <BlockContainer id={blockId} className={classes}>
      {blockId === 'last-block' ? (
        <div className={css.browseAllBlock}>
          <a href={callToAction.href} className={css.browseAllBlockContent}>{callToAction.content}</a>
        </div>
      ) : (
        <ListingCard intl={intl} listing={listing} />
      )}
    </BlockContainer>
  );
};

BlockListing.defaultProps = {
  className: null,
  rootClassName: null,
};

BlockListing.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  callToAction: object,
};

export default BlockListing;
