import { useEffect, useRef, useState } from 'react';
import { submitContactUs } from '../../../../util/api';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useSubmitContact = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const successTimeout = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    return () => {
      clearTimeout(successTimeout);
    };
  }, []);

  const handleSubmit = async values => {
    try {
      if (!executeRecaptcha) {
        console.error('Execute recaptcha not yet available');
        throw new Error('Execute recaptcha not yet available');
      }

      setLoading(true);
      setSuccess(false);
      setError(null);
      const token = await executeRecaptcha('submitContactUsForm');
      await submitContactUs({ token, ...values });
      setLoading(false);
      setSuccess(true);
      successTimeout.current = setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return {
    loading,
    error,
    success,
    handleSubmit,
  };
};
