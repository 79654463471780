import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import * as validators from '../../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../../components';

import css from './SectionContactForm.module.css';

const ContactUsFormComponent = ({ loading, success, error, ...props }) => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        form,
      } = fieldRenderProps;

      if (success) {
        form.restart();
      }

      // email
      const emailRequiredMessage = intl.formatMessage({
        id: 'ContactUsForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ContactUsForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      const classes = classNames(rootClassName || css.formRoot, className);
      const submitInProgress = inProgress || loading;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.formContent}>
            <div className={css.leftFormContent}>
              <div className={css.name}>
                <FieldTextInput
                  className={classNames(css.firstName, css.field)}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  placeholder={intl.formatMessage({
                    id: 'ContactUsName.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'ContactUsName.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={classNames(css.lastName, css.field)}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  placeholder={intl.formatMessage({
                    id: 'ContactUsName.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'ContactUsName.lastNameRequired',
                    })
                  )}
                />
              </div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                className={css.field}
                name="email"
                autoComplete="email"
                placeholder={intl.formatMessage({
                  id: 'ContactUsForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <FieldTextInput
                className={css.subject}
                type="text"
                id={formId ? `${formId}.subject` : 'subject'}
                name="subject"
                autoComplete="subject"
                placeholder={intl.formatMessage({
                  id: 'ContactUsName.subjectPlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ContactUsName.subjectRequired',
                  })
                )}
              />
            </div>
            <div className={css.rightFormContent}>
              <FieldTextInput
                className={css.message}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                autoComplete="message"
                placeholder={intl.formatMessage({
                  id: 'ContactUsName.messagePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ContactUsName.messageRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={css.bottomWrapper}>
            {!!error && (
              <p className={css.error}>
                <FormattedMessage id="ContactUsForm.submitError" />
              </p>
            )}
            {!!success && (
              <p className={css.success}>
                <FormattedMessage id="ContactUsForm.submitSuccess" />
              </p>
            )}
            <PrimaryButton
              className={css.formSubmitBtn}
              type="submit"
              ready={success}
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="ContactUsForm.submit" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);
ContactUsForm.displayName = 'ContactUsForm';

export default ContactUsForm;
