import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import React from 'react';

import SectionArticleDefault from './SectionArticleDefault';
import SectionMailChimpSubscribe from './SectionMailChimpSubscribe';
import SectionArticleCustomHeight from './SectionArticleCustomHeight';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionArticleCustom = props => {
  const { sectionName } = props;

  if (/^custom-height/.test(sectionName)) {
    return <SectionArticleCustomHeight {...props} />;
  }

  if (/^mailchimp-subscribe/.test(sectionName)) {
    return <SectionMailChimpSubscribe {...props} />;
  }

  return <SectionArticleDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticleCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticleCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticleCustom;
