import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';

import SectionColumnsDefault from './SectionColumnsDefault';
import SectionContactForm from './SectionContactForm';

const SectionColumnsCustom = props => {
  const { sectionName } = props;

  if (/^contact-form/.test(sectionName)) {
    return <SectionContactForm {...props} />;
  }

  return <SectionColumnsDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionColumnsCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  numColumns: 1,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionColumnsCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  numColumns: number,
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionColumnsCustom;
