import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionMailChimpSubscribe.module.css';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionMailChimpSubscribe = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={classNames(defaultClasses.sectionDetails, css.formContainer)}>
        <div id="mc_embed_shell">
          <div id="mc_embed_signup">
            <form
              action="https://moestuin.us21.list-manage.com/subscribe/post?u=b687b0f362f54b0e4f80c43c1&amp;id=7a2417f75c&amp;f_id=0072f9e6f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_self"
              noValidate=""
            >
              <div id="mc_embed_signup_scroll">
                <Field data={title} className={defaultClasses.title} options={fieldOptions} />
                <Field
                  data={description}
                  className={classNames(defaultClasses.description, css.description)}
                  options={fieldOptions}
                />
                <div className="mc-field-group">
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="E-mailadres"
                    className="required email"
                    id="mce-EMAIL"
                    required=""
                    defaultValue=""
                  />
                  <div className="clear">
                    <input
                      value="Versturen"
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
                <div hidden="">
                  <input type="hidden" name="tags" defaultValue="316261,316262" />
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }} />
                  <div className="response" id="mce-success-response" style={{ display: 'none' }} />
                </div>
                <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                  <input
                    type="text"
                    name="b_b687b0f362f54b0e4f80c43c1_7a2417f75c"
                    tabIndex={-1}
                    defaultValue=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionMailChimpSubscribe.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionMailChimpSubscribe.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionMailChimpSubscribe;
