import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionArticleCustomHeight.module.css';

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionArticleCustomHeight = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    blocks,
    isInsideContainer,
    options,
  } = props;

  const actualBlocks = blocks.filter(block => block.blockName !== 'set-height');
  const rawHeight = blocks.find(block => block.blockName === 'set-height')?.text?.content;
  const height = rawHeight && !isNaN(rawHeight) ? Number.parseInt(rawHeight) : 'auto';

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const hasBlocks = actualBlocks?.length > 0;

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div style={{ height: height }}>
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field
              data={description}
              className={defaultClasses.description}
              options={fieldOptions}
            />
            <Field
              data={callToAction}
              className={defaultClasses.ctaButton}
              options={fieldOptions}
            />
          </header>
        ) : null}
        {hasBlocks ? (
          <div
            className={classNames(defaultClasses.blockContainer, css.articleMain, {
              [css.noSidePaddings]: isInsideContainer,
            })}
          >
            <BlockBuilder
              blocks={actualBlocks}
              sectionId={sectionId}
              ctaButtonClass={defaultClasses.ctaButton}
              options={options}
            />
          </div>
        ) : null}
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionArticleCustomHeight.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionArticleCustomHeight.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionArticleCustomHeight;
